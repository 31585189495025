let skywise_oauth = 'https://core.skywise.com/foundry-multipass/api/oauth2';
let client_id = '41ddblvjqcld1ur8ml2hpjcqc9';
let client_secret ='t2mf5ndp9qnpgi8s3r7tpq9q6f252nt1p9kcqst46te2f1dtr7j';
let redirect_uri ='https://sde.staging.services.2j17-pub.aws.airbus-v.cloud';
let api_domain ='https://d621qv7e85.execute-api.eu-west-1.amazonaws.com/staging';
let sas_url= 'https://m2b7wzo6uc.execute-api.eu-west-1.amazonaws.com/prod';
let domain = 'https://airbus-stage-10919-2j17.auth.eu-west-1.amazoncognito.com';
let dataset_path ='https://core.skywise.com/workspace/data-integration/dataset/preview';
let sas_env = 'PROD';
let s3_private = 'https://d1c602v012glqz.cloudfront.net/private';
let x_api_key = 'AalRHH4Pom97tvwI477Uo5uTCS8LOlwN3q6xAWdc';
let sas_x_api_key = 'zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w';
let oauth_client_id = '01360217137296287bfd49803a656f99'; 
let oauth_client_secret = '923553515a6562c2f584c4037694a3b9';
let oauth_redirect_url ='https://sde.staging.services.2j17-pub.aws.airbus-v.cloud/SDE/ExportDS';
export const DOMAIN = domain;
export const CLIENT_ID = client_id;
export const CLIENT_SECRET = client_secret;
export const REDIRECT_URI = redirect_uri;
export const API_DOMAIN = api_domain;
export const SAS_URL = sas_url;
export const DATASET_PATH = dataset_path;
export const SAS_ENV = sas_env;
export const S3_PRIVATE = s3_private;
export const X_API_KEY = x_api_key;
export const SAS_X_API_KEY = sas_x_api_key;
export const SKYWISE_OAUTH = skywise_oauth;
export const OAUTH_REDIRECT_URI = oauth_redirect_url;
export const OAUTH_CLIENT_ID = oauth_client_id;
export const OAUTH_CLIENT_SECRET = oauth_client_secret;
